import _cloneDeep from 'lodash/cloneDeep';
import _pick from 'lodash/pick';
import _upperFirst from 'lodash/upperFirst'
import { scrollContainerToActiveChild } from 'services/utils';
import { DELETE_EMULATOR } from 'services/Permissions';
import {
    getStatusMessage,
    animateSensorIcon,
    RESERVED_LABEL_KEYS
} from 'services/SensorHelper';
import * as DeviceTypes from 'services/config/SensorTypes';
import imgTinySensor from '../../../assets/images/img_tiny_sensor.svg';
import imgCcon from '../../../assets/images/img-ccon.svg';
import imgCO2 from '../../../assets/images/img_co2.png';
import imgMotion from '../../../assets/images/img_motion.png';


const SUPPORTED_DEVICES = [
    "temperature",
    "proximity",
    "contact",
    "touch",
    "humidity",
    "touchCounter",
    "proximityCounter",
    "waterDetector",
    "co2",
    "motion",
    "deskOccupancy",
    "ccon"
]

const SUPPORTED_EVENTS = {
    temperature: [
        "temperature",
        "batteryStatus",
        "networkStatus",
        "touch"
    ],
    proximity: [
        "objectPresent",
        "batteryStatus",
        "networkStatus",
        "touch"
    ],
    contact: [
        "contact",
        "batteryStatus",
        "networkStatus",
    ],
    motion: [
        "motion",
        "networkStatus",
        "batteryStatus",
    ],
    touch: [
        "touch",
        "batteryStatus",
        "networkStatus"
    ],
    humidity: [
        "humidity",
        "batteryStatus",
        "networkStatus",
        "touch"
    ],
    "touchCounter": [
        "touchCount",
        "batteryStatus",
        "networkStatus",
        "touch"
    ],
    "proximityCounter": [
        "objectPresentCount",
        "batteryStatus",
        "networkStatus",
        "touch"
    ],
    "waterDetector": [
        "waterPresent",
        "networkStatus",
        "batteryStatus",
        "touch"
    ],
    "co2": [
        "CO2, Humidity & Pressure",
        "batteryStatus",
        "networkStatus",
    ],
    "deskOccupancy": [
        "deskOccupancy",
        "batteryStatus",
        "networkStatus",
        "touch",
    ],
    "ccon": [
        "connectionStatus",
        "cellularStatus",
        "ethernetStatus",
        "touch"
    ]
};

/* @ngInject */
export default class EmulatorDetailsController {
    constructor(
        EventEmitter,
        SensorService,
        RoleManager,
        $transitions,
        $state,
        $timeout,
        DeviceEmulator,
        ToastService,
        $scope
    ) {
        this.EventEmitter = EventEmitter;
        this.SensorService = SensorService;
        this.RoleManager = RoleManager;
        this.$transitions = $transitions;
        this.$state = $state;
        this.$timeout = $timeout;
        this.ToastService = ToastService;
        this.DeviceEmulator = DeviceEmulator;
        this.$scope = $scope;
        this.tinyIcon = imgTinySensor;
        this.cconIcon = imgCcon;
        this.imgCO2 = imgCO2;
        this.imgMotion = imgMotion;
        this.DeviceTypes = _pick(DeviceTypes, SUPPORTED_DEVICES);
        this.SupportedEvents = SUPPORTED_EVENTS;
        this.upperFirst = _upperFirst;

        this.emulateEvent = "";
        this.filteredLabels = {};

    }

    get canDeleteEmulator() {
        return this.RoleManager.can(DELETE_EMULATOR);
    }

    get isCounterEvent() {
        const i = ["objectPresentCount", "touchCount"]
            .findIndex(e => e === this.emulateEvent);
        return i >= 0
    }

    $onInit() {
        this.originalDevice = _cloneDeep(this.device);
        if (!this.isNew) {
            this.emulateEvent = this.SupportedEvents[this.device.type][0] // Default to first supported event type
        }
        this.$timeout(scrollContainerToActiveChild);
        this.filterLabels();
        if (!this.isNew) {
            this.eventSubscription = this.SensorService
                .createObservableFromThingUpdates(this.device.id)
                .subscribe(event => this.onNewEvents(event));
        };
    }

    $onDestroy() {
        if (this.eventSubscription) {
            this.eventSubscription.unsubscribe();
        }
    }

    onNewEvents(event) {
        this.device.reported = {
            ...this.device.reported,
            ...event.data
        };
        if (this.device.type !== "contact") {
            this.rippleOnSensor();
        } 
        this.$scope.$applyAsync();
    }

    get statusMessage() {
        return getStatusMessage(this.device);
    }

    get isValidDevice() {
        if (this.device.type === "") {
            return false;
        }
        return true;
    }

    filterLabels() {
        this.filteredLabels = Object.keys(this.device.labels)
            .filter(key => RESERVED_LABEL_KEYS.indexOf(key) < 0)
            .reduce((acc, key) => {
                acc[key] = this.device.labels[key];
                return acc;
            }, {});
    }

    rippleOnSensor() {
        if (!this.sensorIconNode) {
            this.sensorIconNode = document.getElementById('sensor-icon');
        }
        animateSensorIcon(this.sensorIconNode);
    }

    createLabel({ label }) {
        if (this.isNew) {
            this.device.labels[label.key] = label.value;
            this.filterLabels();
        }
        return Promise.resolve();
    }

    updateLabel({ labelKey, value }) {
        if (this.isNew) {
            this.device.labels[labelKey] = value;
            this.filterLabels();
        }
        return Promise.resolve();
    }

    removeLabel({ labelKey }) {
        if (this.isNew) {
            delete this.device.labels[labelKey];
            this.filterLabels();
        }
        return Promise.resolve();
    }

    createDevice() {

        // Prevent a Door & Window Sensor from being displayed as the default "Contact Sensor" name
        if (this.device.type === "contact" && this.device.labels.name === "") {
            this.device.labels.name = "Door & Window Sensor";
        }

        return this.onCreate(
            this.EventEmitter({
                device: this.device
            })
        )
    }

    updateName({ patch: { name } }) {
        this.updateLabel({ labelKey: "name", value: name });
    }


    publishEvent({ event }) {
        return this.onPublishEvent(
            this.EventEmitter({
                device: this.device,
                event
            })
        );
    }

    deleteDevice() {
        return this.onDelete(
            this.EventEmitter({
                device: this.device,
            })
        )
    }

}