import angular from 'angular';

import LayoutComponent from './layout';
import ContentComponent from './content';
import ContentHeaderComponent from './content-header';
import HeaderComponent from './header';
import { LoaderComponent, LoaderService } from './loader';
import {
    IconComponent, IconDirective
} from './icon';
import ScreenshotDirective from './screenshot';
import DragDrop from './drag-drop';
import ShyDirective from './shy';
import GetFocusDirective from './get-focus';
import GetWidthDirective from './get-width';
import BackropDirective from './backdrop';
import BlockLoaderDirective from './block-loader';
import ChartComponent from './chart';
import * as Navigation from './navbar';
import RadioStatusComponent from './radio-status';
import RadioWideComponent from './radio-status-wide';
import RadioCloudConnectorComponent from './radio-status-ccon';
import EntitiesAutocompleteComponent from './entities-autocomplete';
import SearchAutocompleteComponent from './search-autocomplete';
import LabelsAutocompleteComponent from './labels-autocomplete';
import SearchBox from './search-box';
import UserPopover from './user-popover';
import SplashScreen from './splash-screen';
import mdCustomColumnDirective from './md-custom-column';
import OnEnterDirective from './on-enter';
import DetailViewToolbar from './detail-view-toolbar';
import PaginatorComponent from './paginator';
import CopyableInputComponent from './copyable-input';
import MessageBoxComponent from './message-box';
import StickyDirective from './sticky';
import ChartRangeSelectorComponent from './chart-range-selector';
import TouchChartComponent from './chart-touch';
import TemperatureChartComponent from './chart-temperature';
import ProximityChartComponent from './chart-proximity';
import ProximityChartAggregatedComponent from './chart-proximity-agg';
import ContactChartComponent from './chart-contact';
import ContactChartAggregatedComponent from './chart-contact-agg';
import DeskOccupancyChartComponent from './chart-desk-occupancy';
import DeskOccupancyAggChartComponent from './chart-desk-occupancy-agg';
import ProximityChartAdvancedComponent from './chart-proximity-advanced';
import ProximityChartAuxiliaryChartComponent from './chart-proximity-advanced/auxiliary-chart';
import MotionChartComponent from './chart-motion';
import MotionChartAggregatedComponent from './chart-motion-agg';
import CountingChartComponent from './chart-counting';
import WaterDetectorChartComponent from './chart-water-detector';
import HumidityChartComponent from './chart-humidity';
import AnalogChartComponent from './chart-analog';
import TiltChartComponent from './chart-tilt'; // TILT-SUPPORT
import CO2ChartComponent from './chart-co2';
import IconConfig from './icon/icon.config';
import IconBootstrap from './icon/icon.bootstrap';
import InstallationAlertBoxComponent from './installation-alert-box';
import ClaimDevicesComponent from './claim-devices';

const ngMaterial = require('angular-material');

/**
 * Common module name
 */
const dtCommon = 'dt.common';
export default dtCommon;

const module = angular.module(dtCommon, [ngMaterial]);
module
    .component(ContentComponent.selector, ContentComponent.options)
    .component(ContentHeaderComponent.selector, ContentHeaderComponent.options)
    .component(HeaderComponent.selector, HeaderComponent.options)
    .directive(ScreenshotDirective.selector, ScreenshotDirective.options)
    .directive(DragDrop.selector, DragDrop.options)
    .component(IconComponent.selector, IconComponent.options)
    .directive(IconDirective.selector, IconDirective.options)
    .directive(ShyDirective.selector, ShyDirective.options)
    .directive(GetFocusDirective.selector, GetFocusDirective.options)
    .directive(GetWidthDirective.selector, GetWidthDirective.options)
    .directive(BackropDirective.selector, BackropDirective.options)
    .directive(BlockLoaderDirective.selector, BlockLoaderDirective.options)
    .directive(OnEnterDirective.selector, OnEnterDirective.options)
    .component(ChartComponent.selector, ChartComponent.options)
    .component(LayoutComponent.selector, LayoutComponent.options)
    .component(LoaderComponent.selector, LoaderComponent.options)
    .service(LoaderService.name, LoaderService.definition)
    .component(Navigation.LeftNavigationComponent.selector, Navigation.LeftNavigationComponent.options)
    .component(RadioStatusComponent.selector, RadioStatusComponent.options)
    .component(RadioWideComponent.selector, RadioWideComponent.options)
    .component(RadioCloudConnectorComponent.selector, RadioCloudConnectorComponent.options)
    .component(SearchBox.selector, SearchBox.options)
    .component(UserPopover.selector, UserPopover.options)
    .directive(mdCustomColumnDirective.selector, mdCustomColumnDirective.options)
    .component(SearchAutocompleteComponent.selector, SearchAutocompleteComponent.options)
    .component(EntitiesAutocompleteComponent.selector, EntitiesAutocompleteComponent.options)
    .component(LabelsAutocompleteComponent.selector, LabelsAutocompleteComponent.options)
    .component(SplashScreen.selector, SplashScreen.options)
    .component(DetailViewToolbar.selector, DetailViewToolbar.options)
    .component(PaginatorComponent.selector, PaginatorComponent.options)
    .component(CopyableInputComponent.selector, CopyableInputComponent.options)
    .component(MessageBoxComponent.selector, MessageBoxComponent.options)
    .directive(StickyDirective.selector, StickyDirective.options)
    .component(ChartRangeSelectorComponent.selector, ChartRangeSelectorComponent.options)
    .component(TouchChartComponent.selector, TouchChartComponent.options)
    .component(TemperatureChartComponent.selector, TemperatureChartComponent.options)
    .component(ProximityChartComponent.selector, ProximityChartComponent.options)
    .component(ProximityChartAggregatedComponent.selector, ProximityChartAggregatedComponent.options)
    .component(ContactChartComponent.selector, ContactChartComponent.options)
    .component(ContactChartAggregatedComponent.selector, ContactChartAggregatedComponent.options)
    .component(DeskOccupancyChartComponent.selector, DeskOccupancyChartComponent.options)
    .component(DeskOccupancyAggChartComponent.selector, DeskOccupancyAggChartComponent.options)
    .component(ProximityChartAdvancedComponent.selector, ProximityChartAdvancedComponent.options)
    .component(
        ProximityChartAuxiliaryChartComponent.selector,
        ProximityChartAuxiliaryChartComponent.options
    )
    .component(MotionChartComponent.selector, MotionChartComponent.options)
    .component(
        MotionChartAggregatedComponent.selector,
        MotionChartAggregatedComponent.options
    )
    .component(
        CountingChartComponent.selector,
        CountingChartComponent.options
    )
    .component(
        WaterDetectorChartComponent.selector,
        WaterDetectorChartComponent.options
    )
    .component(
        HumidityChartComponent.selector,
        HumidityChartComponent.options
    )
    .component(
        AnalogChartComponent.selector,
        AnalogChartComponent.options
    )
    .component( // TILT-SUPPORT
        TiltChartComponent.selector,
        TiltChartComponent.options
    )
    .component(
        CO2ChartComponent.selector,
        CO2ChartComponent.options
    )
    .component(
        InstallationAlertBoxComponent.selector, 
        InstallationAlertBoxComponent.options
    )
    .component(
        ClaimDevicesComponent.selector, 
        ClaimDevicesComponent.options
    )

module.config(IconConfig);
module.run(IconBootstrap);
