import moment from 'moment';
import 'moment-timezone';

import { 
    INTEGRATIONS_DROPDOWN_OPEN_EVENT, 
    SHOW_MISSING_BILLING_INFO_BADGE,
    REMOVE_MISSING_BILLING_INFO_BADGE,
    SHOW_OVERDUE_INVOICE_BADGE,
    REMOVE_OVERDUE_INVOICE_BADGE,
    PROJECT_CHANGED
} from 'services/StudioEvents';
import { truncateString, getHoursMinutesFormat } from 'services/utils';

import imgLogo from 'file-loader!../../../sass/dt-design-system/src/scss/assets/dt-logo--light.svg'; //eslint-disable-line
import { CREATE_MEMBERSHIP } from 'services/Permissions';
import { States } from '../../../app.router';

import ApplicationHelpController from './popups/application-help/controller';
import ApplicationHelpTemplate from './popups/application-help/template.html';
import ProfileController from './popups/profile/controller';
import ProfileTemplate from './popups/profile/template.html';
import NewsController from './popups/news/controller';
import NewsTemplate from './popups/news/template.html';
import ResourcesController from './popups/resources/controller';
import ResourcesTemplate from './popups/resources/template.html';
import FeedbackFormController from './popups/feedback-form/controller';
import FeedbackFormTemplate from './popups/feedback-form/template.html';
import ProjectDropDownController from '../../header/projects-modal/controller';
import ProjectDropDownTemplate from '../../header/projects-modal/template.html';
import QRModalController from '../../header/qr-modal/controller';
import QRModalTemplate from '../../header/qr-modal/template.html';

/* @ngInject */
export default class LeftNavigationController {
    constructor(AuthService, ProjectManager, $state, $mdPanel, DialogService, $rootScope, RoleManager, IAMService, StorageService, AnnouncementManager) {
        this.AuthService = AuthService;
        this.ProjectManager = ProjectManager;
        this.$state = $state;
        this.$mdPanel = $mdPanel;
        this.DialogService = DialogService;
        this.$rootScope = $rootScope;
        this.RoleManager = RoleManager;
        this.IAMService = IAMService;
        this.StorageService = StorageService;
        this.AnnouncementManager = AnnouncementManager;

        this.showFeedbackForm = this.showFeedbackForm.bind(this);
        this.cancelDialog = this.cancelDialog.bind(this);
    }

    $onInit() {
        const authData = this.AuthService.getAuthData();

        if (authData) {
            this.userProfile = authData.profile;
        }

        this.collapsed = false;
        this.imgLogo = imgLogo;

        // Local state for dropdown children of nav items
        this.showIntegrationChildren = false;
        this.showOrganizationChildren = false;

        if ([States.DATA_CONNECTORS, States.DATA_CONNECTOR_DETAILS,
            States.SERVICE_ACCOUNTS, States.SERVICE_ACCOUNT_DETAILS, 
            States.EMULATOR, States.EMULATOR_DETAILS].includes(this.$state.current.name)) {
            this.showIntegrationChildren = true;
        }

        this.$rootScope.$on(INTEGRATIONS_DROPDOWN_OPEN_EVENT, () => {
            this.showIntegrationChildren = true;
        })

        // Billing badges "MISSING INFO" & "OVERDUE INVOICE"
        this.missingBillingInfo = false
        this.overdueInvoice = false

        this.$rootScope.$on(SHOW_MISSING_BILLING_INFO_BADGE, () => {
            this.missingBillingInfo = true
            this.$rootScope.$applyAsync()
        })

        this.$rootScope.$on(REMOVE_MISSING_BILLING_INFO_BADGE, () => {
            this.missingBillingInfo = false
            this.$rootScope.$applyAsync()
        })

        this.$rootScope.$on(SHOW_OVERDUE_INVOICE_BADGE, () => {
            this.overdueInvoice = true
            this.$rootScope.$applyAsync()
        })

        this.$rootScope.$on(REMOVE_OVERDUE_INVOICE_BADGE, () => {
            this.overdueInvoice = false
            this.$rootScope.$applyAsync()
        })
        
        this.projectTimeZone = ''
        if (this.ProjectManager.currentProject.location) {
            this.projectTimeZone = this.ProjectManager.currentProject.location.timeLocation
        }
        this.updateTimeGloballyForStudio(this.projectTimeZone)
        this.equalToLocalTimeZone = true

        this.updateLocalTimestamp();
        
        // Update the local timestamp every second
        setInterval(() => {
            this.updateLocalTimestamp();
        }, 1000);

        this.$rootScope.$on('TIME_ZONE_CHANGED', (_, data) => {
            this.projectTimeZone = data.timeZone
            if (this.projectTimeZone === '') {
                this.equalToLocalTimeZone = true
            } else {
                this.updateLocalTimestamp()
            }
            this.updateTimeGloballyForStudio(this.projectTimeZone)
        })

        this.$rootScope.$on(PROJECT_CHANGED, () => {
            this.projectTimeZone = this.ProjectManager.currentProject.location.timeLocation
            if (this.projectTimeZone === '') {
                this.equalToLocalTimeZone = true
            } else {
                this.updateLocalTimestamp()
            }
            this.updateTimeGloballyForStudio(this.projectTimeZone)
        })

        // Show the "What's New" modal if there are unseen announcements, but not to completely new users
        if (this.AnnouncementManager.getLastSeenAnnouncementNumber() > 0 && this.AnnouncementManager.hasUnseenAnnouncements) {
            this.showWhatsNew()
        }
    }

    updateTimeGloballyForStudio(timeLocation) {
        if (timeLocation === '') {
            moment.tz.setDefault()
            if (window.Highcharts) {
                window.Highcharts.setOptions({
                    time: {
                        useUTC: false,
                    }
                })
            }
        } else {
            moment.tz.setDefault(timeLocation)
            if (window.Highcharts) {
                window.Highcharts.setOptions({
                    time: {
                        useUTC: true,
                        timezone: timeLocation
                    }
                })
            }
        }
    }

    updateLocalTimestamp() {
        if (this.projectTimeZone !== '') {
            this.localTimestamp = moment().tz(this.projectTimeZone).format(getHoursMinutesFormat());
            this.timezoneDifferenceText = this.formatTimezoneDifference(this.projectTimeZone);
            this.$rootScope.$applyAsync();
        }
    }

    // Function to format the timezone difference
    formatTimezoneDifference(projectTimeZone) {
        const localTimezone = moment.tz.guess();
        const projectTime = moment().tz(projectTimeZone);
        const localTime = moment().tz(localTimezone);

        // Calculate the difference in hours
        const diffHours = projectTime.utcOffset() - localTime.utcOffset();
        const diffInHours = diffHours / 60;  // Convert minutes to hours

        // Determine the text to show based on the time difference
        let timeDiffText = '';
        if (diffInHours > 0) {
            timeDiffText = `${diffInHours} hr${Math.abs(diffInHours) === 1 ? '' : 's'} ahead`;
            this.equalToLocalTimeZone = false
        } else if (diffInHours < 0) {
            timeDiffText = `${Math.abs(diffInHours)} hr${Math.abs(diffInHours) === 1 ? '' : 's'} behind`;
            this.equalToLocalTimeZone = false
        } else {
            this.equalToLocalTimeZone = true
        }
        return timeDiffText;
    }

    get isOrganizationAdmin() {
        return this.RoleManager.hasOrganizationPermissionTo(CREATE_MEMBERSHIP);
    }

    toggle() {
        this.collapsed = !this.collapsed;
        if (this.onToggle) {
            this.onToggle({
                isCollapsed: this.collapsed
            });
        }
    }

    isProjectsActive() {
        return [States.PROJECTS, States.PROJECT_DETAILS].includes(this.$state.current.name);
    }
    
    isOrgDetailsActive() {
        return [States.ORGANIZATION_DETAILS].includes(this.$state.current.name);
    }
    
    isOrgSettingsActive() {
        return [States.ORGANIZATION_SETTINGS, States.ORGANIZATION_SETTINGS_MEMBER].includes(this.$state.current.name);
    }

    isBillingActive() {
        return [States.ORGANIZATION_BILLING].includes(this.$state.current.name);
    }

    isItemActive(item) {
        return item.activeOnStates.includes(this.$state.current.name);
    }

    hasChildren(item) { // eslint-disable-line class-methods-use-this
        if (item.children) {
            return item.children.length > 0;
        }
        return false
    }

    toggleChildren(item) {
        if (item.name === 'API Integrations') {
            this.showIntegrationChildren = !this.showIntegrationChildren;
        }
    }

    shouldDisplayChildren(item) {
        if (item.name === 'API Integrations') {
            return this.showIntegrationChildren;
        }
        return false
    }

    getErrorState(item) {
        if (item.alertKey && this.ProjectManager.currentProject && this.ProjectManager.currentProject.alertsSummary) {
            return this.ProjectManager.currentProject.alertsSummary[item.alertKey]
        }
        return null
    }

    hasErrors(item) {
        const state = this.getErrorState(item);
        return state && state.activeErrors > 0;
    }

    hasWarnings(item) {
        const state = this.getErrorState(item);
        return state && state.activeWarnings > 0 && !this.hasErrors(item);
    }

    get isCollapsed() {
        return this.collapsed;
    }

    showProjectsModal(ev) {
        this.DialogService.show({
            controller: ProjectDropDownController,
            controllerAs: '$ctrl',
            template: ProjectDropDownTemplate,
            parent: document.body,
            targetEvent: ev,
            clickOutsideToClose: true,
            escapeToClose: true,
            fullscreen: true,
        });
    }

    showQRModal(ev) {
        this.DialogService.show({
            controller: QRModalController,
            controllerAs: '$ctrl',
            template: QRModalTemplate,
            parent: document.body,
            targetEvent: ev,
            clickOutsideToClose: true,
            escapeToClose: true,
            fullscreen: true,
        });
    }

    truncateProjectName(num) {
        const projectName = this.ProjectManager.currentProjectName;
        if (this.ProjectManager.currentProjectId) {
            return projectName ? truncateString(projectName, num) : "(Missing Project name)"
        } 
        return "Select Project"
    }

    truncateOrganizationName(num) {
        const organizationName = this.ProjectManager.currentProject.organizationDisplayName;
        return organizationName ? truncateString(organizationName, num) : "";
    }

    showUserInfo(ev) {
        this.hideMobileNav()
        this.DialogService.show({
            controller: ProfileController,
            controllerAs: '$ctrl',
            template: ProfileTemplate,
            parent: document.body,
            targetEvent: ev,
            clickOutsideToClose: true,
            escapeToClose: true,
            fullscreen: false,
        });
    }

    showWhatsNew(ev) {
        this.AnnouncementManager.setUpToDate()
        this.hideMobileNav()
        this.DialogService.show({
            controller: NewsController,
            controllerAs: '$ctrl',
            template: NewsTemplate,
            parent: document.body,
            targetEvent: ev,
            clickOutsideToClose: true,
            escapeToClose: true,
            fullscreen: false,
        });
    }

    showResources(ev) {
        this.hideMobileNav()
        this.DialogService.show({
            controller: ResourcesController,
            controllerAs: '$ctrl',
            template: ResourcesTemplate,
            parent: document.body,
            targetEvent: ev,
            clickOutsideToClose: true,
            escapeToClose: true,
            fullscreen: false,
        });
    }

    showHelpMenu(ev) {
        this.hideMobileNav()
        this.DialogService.show({
            controller: ApplicationHelpController,
            controllerAs: '$ctrl',
            template: ApplicationHelpTemplate,
            parent: document.body,
            targetEvent: ev,
            clickOutsideToClose: true,
            escapeToClose: true,
            fullscreen: false,
            locals: {
                onShowFeedbackForm: this.showFeedbackForm
            }
        });
    }

    showFeedbackForm() {
        this.DialogService.cancel()
        this.DialogService.show({
            controller: FeedbackFormController,
            controllerAs: '$ctrl',
            template: FeedbackFormTemplate,
            parent: document.body,
            clickOutsideToClose: true,
            escapeToClose: true,
            fullscreen: true,
            locals: {
                onCancelDialog: this.cancelDialog
            }
        });
    }

    toggleOrganization() {
        this.showOrganizationChildren = !this.showOrganizationChildren;
    }
    
    openOrgDetails() {
        this.$state.go(States.ORGANIZATION_DETAILS);
    }

    openOrgSettings() {
        if(this.isOrganizationAdmin) {
            this.$state.go(States.ORGANIZATION_SETTINGS);
        }
    }

    openBilling() {
        this.$state.go(States.ORGANIZATION_BILLING);
    }

    cancelDialog() {
        this.DialogService.cancel()
    }
}
