import _get from 'lodash/get';
/* @ngInject */
export default class EmulatorConnectionStatusController {
    constructor(
        EventEmitter,
    ) {
        this.EventEmitter = EventEmitter;
        this.connectionTypes = [
            "OFFLINE",
            "ETHERNET",
            "CELLULAR"
        ];
    }



    $onInit() {
        this.event = {
            connectionStatus: {
                connection: _get(this.device, "reported.connectionStatus.connection", "OFFLINE"),
                available: ["ETHERNET", "CELLULAR"]
            }
        }
    }

    sendEvent() {
        this.onPublishEvent(
            this.EventEmitter({
                event: this.event
            })
        );
    }
}