export default {
    range: '<',
    thing: '<',
    filter: '<',
    eventsObservable: '<',
    greatestRangeLabel: '<',
    onChartSelection: '&',
    onMetadataChanged: '&',
    thresholds: '<?',
    onInitialized: '&'
};
