import controller from './connectivity-chart.controller';

const template = require('./connectivity-chart.html');

export default {
    bindings: {
        thing: '<',
        eventsObservable: '<'
    },
    template,
    controller
};
