import Config from 'services/config/local';
import { injectIdProp } from 'services/utils';
import {
    buildApiUrl, extractData, filterEmptyParams, buildUpdateMask
} from './helper';


export default class Resource {
    constructor($http, version = Config.api.version, host = Config.api.host) {
        this.$http = $http;
        this.version = version;
        this.host = host;
    }

    list(url, params, resultKey) {
        return this.$http.get(buildApiUrl(url,this.version, this.host), {
            params: filterEmptyParams(params)
        })
            .then(extractData)
            .then((response) => {
                const data = response[resultKey].map(injectIdProp) || [];
                delete response[resultKey];
                return {
                    data,
                    ...response
                };
            });
    }

    get(name) {
        return this.$http.get(buildApiUrl(name, this.version, this.host))
            .then(extractData)
            .then(injectIdProp);
    }

    create(url, data) {
        return this.$http.post(buildApiUrl(url, this.version, this.host), data)
            .then(extractData)
            .then(injectIdProp);
    }

    update(name, data, updateMask) {
        return this.$http.patch(buildApiUrl(name, this.version, this.host), data, {
            params: {
                'updateMask': updateMask || buildUpdateMask(data)
            }
        })
            .then(extractData)
            .then(injectIdProp);
    }

    put(name, data) {
        return this.$http.put(buildApiUrl(name, this.version, this.host), data)
            .then(extractData)
            .then(injectIdProp)
    }

    remove(name) {
        return this.$http.delete(buildApiUrl(name, this.version, this.host))
            .then(extractData);
    }
}
