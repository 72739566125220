import moment from 'moment';
import _cloneDeep from 'lodash/cloneDeep';
import { LAYOUT_MOBILE_BREAKPOINT, isMobileLayout } from 'services/utils';
import { 
    MEASUREMENT_SYSTEM_CHANGED_EVENT, 
    HEALTH_CHECKER_NETWORK_UP_EVENT, 
    HEALTH_CHECKER_PAGE_VISIBLE_EVENT, 
    DASHBOARD_REFRESH_EVENT, 
    SIMULATE_FULLSCREEN,
    STOP_SIMULATE_FULLSCREEN
} from 'services/StudioEvents';
import { 
    DASHBOARD_APPEARANCE_CO2_HEATMAP, 
    DASHBOARD_APPEARANCE_MOTION_HEATMAP,
    DASHBOARD_APPEARANCE_MULTIPLE_TEMPERATURE,
    DASHBOARD_APPEARANCE_MULTIPLE_HUMIDITY,
    DASHBOARD_APPEARANCE_DESK_OCCUPANCY_AGGREGATED
} from 'constants/device';
import {
    HEARTBEAT_MAX_GAP,
} from 'services/charting/data-converter';
import { MAX_CARDS } from 'services/api/DashboardService';
import { UPDATE_DEVICE } from 'services/Permissions';
import { getHistoryEventType, NETWORK_STATUS_TIME_WINDOW } from 'services/SensorHelper';
import CreateCardController from './create-card/controller';
import CreateCardTemplate from './create-card/template.html';

import EditDevicesController from './edit-devices-modal/controller'
import EditDevicesTemplate from './edit-devices-modal/template.html'

import RangePickerController from '../../common/range-picker/controller';
import RangePickerTemplate from '../../common/range-picker/template.html';

import ExtendedStorageInfoController from '../../common/extended-storage-info/controller';
import ExtendedStorageInfoTemplate from '../../common/extended-storage-info/template.html';
import { States } from '../../app.router';

export const DASHBOARD_TITLE = 'Project Dashboard';

/* eslint class-methods-use-this: 0 */
/* @ngInject */
export default class DashboardController {
    constructor(ProjectManager, DashboardService, DialogService, Loader, $rootScope, $scope, SensorService, $interval, ToastService, AnalyticsService, RoleManager, $mdPanel, $state, FeatureFlags) {  
        this.ProjectManager = ProjectManager;
        this.DashboardService = DashboardService;
        this.DialogService = DialogService;
        this.Loader = Loader;
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.SensorService = SensorService;
        this.$interval = $interval;
        this.toastService = ToastService;
        this.AnalyticsService = AnalyticsService;
        this.RoleManager = RoleManager;
        this.$mdPanel = $mdPanel;
        this.$state = $state;
        this.FeatureFlags = FeatureFlags;

        this.CardType = {
            Device: "DEVICE",
        }
    }

    get formattedStartTime() {
        return moment(this.zoomStartTime).format('MMM D')
    }

    get formattedEndTime() {
        return moment(this.zoomEndTime).format('MMM D')
    }

    get hasLongTermStorageFeatureFlag() {
        return this.FeatureFlags.isActive('sensor_long_term_storage')
    }

    $onInit() {
        this.showResetZoom = false;
        this.isFullscreen = false;
        this.isEditingCard = false;
        this.cardBeingEdited = null;
        this.showingRangePicker = false
        this.showRangeOptionsDropdown = false
        this.isMobile = isMobileLayout()
        
        this.stateTitle = DASHBOARD_TITLE;

        // All Gridster options are documented at
        // https://github.com/tiberiuzuld/angular-gridster2/tree/1.x
        this.options = {
            gridType: 'verticalFixed',
            compactType: 'compactUp',
            outerMargin: true,
            fixedRowHeight: 156,

            margin: 18,
            minCols: 4,
            maxCols: 4,
            maxItemCols: 4,

            disableScrollHorizontal: true,
            disableScrollVertical: true,
            scrollSensitivity: -200,

            mobileBreakpoint: LAYOUT_MOBILE_BREAKPOINT,
            
            pushItems: true,
            pushDirections: {north: true, east: false, south: true, west: false},
            disablePushOnDrag: false,
            swap: true,
            scrollToNewItems: false,

            draggable: {
                enabled: true,
                start: this.dragStart.bind(this),
                stop: this.dragStop.bind(this)
            },

            resizable: {
                enabled: true,
                start: this.resizeStart.bind(this),
                stop: this.resizeStop.bind(this)
            }
        };
      
        this.duration = ''
        this.previousDuration = ''

        this.extendedStorageDurations = ["LAST_3_MONTHS", "LAST_6_MONTHS", "LAST_12_MONTHS", "LAST_3_YEARS"]
        
        // Zoom start and end time is passed into every dashboard card
        this.zoomStartTime = undefined; 
        this.zoomEndTime = undefined;
        this.cards = [];
        this.cardsToAdd = [];

        // State keeping for Multiple Dashboards
        this.currentDashboard = {}
        this.dashboards = []
        this.showDashboardsDropdown = false
        this.creatingDashboard = false
        this.newDashboardName = ''
        this.renamingDashboard = false
        this.renamingDashboardId = '' // Used to enable dynamic ng-class for other dashboards in list
        this.updatedDashboardName = ''

        // Determine what Dashboard to show
        this.Loader.promise = this.DashboardService.listDashboards(this.ProjectManager.currentProjectId).then(response => {
            this.dashboards = response.dashboards
            let foundDashboardIdFromUrl = false
            if (this.$state.params.dashboardId !== undefined) {
                // Check if dashboardId is in the list of dashboards
                foundDashboardIdFromUrl = this.dashboards.some(d => d.dashboardId === this.$state.params.dashboardId)
            }
            if (foundDashboardIdFromUrl) { // A dashboardId was provided in the URL
                this.getDashboardConfig(this.$state.params.dashboardId)
            } else {
                // Check the last visited Dashboard for this Project
                const lastVisitedDashboard = this.DashboardService.getLastVisitedDashboard(this.ProjectManager.currentProjectId)
                // Check if the Dashboard still exists
                if (this.dashboards.some(d => d.dashboardId === lastVisitedDashboard)) { 
                    this.getDashboardConfig(lastVisitedDashboard)
                } else if (this.dashboards.length > 0) { // No last visited for this Project, check if there are any existing before defaulting to 'default'
                    this.getDashboardConfig(this.dashboards[0].dashboardId)
                } else {
                    this.getDashboardConfig('default')
                }
            }
        })
        
        this.$rootScope.$on(MEASUREMENT_SYSTEM_CHANGED_EVENT, () => {
            this.getDashboardConfig(this.currentDashboard.dashboardId) // Need to fully redraw graphs to update y-axis units
        })

        // Listen for zoom event on any card, then let the others know and let them fetch new content
        // Also used when setting a custom range from the date picker
        this.$rootScope.$on('dashboardZoom', (event, data) => {            
            this.showResetZoom = data.showResetZoom // Only show zoom button on actual zoom events
            this.zoomStartTime = data.min;
            this.zoomEndTime = data.max;
            this.previousDuration = this.duration
            this.duration = 'CUSTOM'

            const startTime = moment(this.zoomStartTime - HEARTBEAT_MAX_GAP).utc();
            const endTime = moment(this.zoomEndTime + HEARTBEAT_MAX_GAP).utc();

            this.updateCardsContent(startTime, endTime);

            this.removeTooltips()
        });
        
        this.streamSubscription = this.setupDeviceStream();

        // Keep track of fullscreen state to show and hide certain dashboard elements
        if (document.addEventListener) {
            document.addEventListener('fullscreenchange',       this.exitFullscreenHandler.bind(this), false);
            document.addEventListener('mozfullscreenchange',    this.exitFullscreenHandler.bind(this), false);
            document.addEventListener('MSFullscreenChange',     this.exitFullscreenHandler.bind(this), false);
            document.addEventListener('webkitfullscreenchange', this.exitFullscreenHandler.bind(this), false);
        }

        this.networkUpEventListener = this.$rootScope.$on(HEALTH_CHECKER_NETWORK_UP_EVENT, this.performHealthCheck.bind(this));
        this.pageVisibleEventListener = this.$rootScope.$on(HEALTH_CHECKER_PAGE_VISIBLE_EVENT, this.performHealthCheck.bind(this)); 
        this.dashboardRefreshListener = this.$rootScope.$on(DASHBOARD_REFRESH_EVENT, this.performHealthCheck.bind(this)); 
    }

    performHealthCheck() {
        this.SensorService.isInternetAccessible().then((internetAccessible) => {
            if (internetAccessible) {
                this.getDashboardConfig(this.currentDashboard.dashboardId);
                this.streamSubscription.stop();
                this.streamSubscription = this.setupDeviceStream();
            } else {
                window.location.reload(true);
            }
        });
    }

    // Returns whether or not the dashboard has the maximum allowed number of cards added
    get hasMaxedOutCards() {
        return this.cards.length >= this.maxCards;
    }

    // Returns the maximum number of allowed cards per dashboard
    get maxCards() {
        return MAX_CARDS;
    }

    get canUpdateDashboard() {
        return this.RoleManager.can(UPDATE_DEVICE);
    }

    $onDestroy() {
        if (this.streamSubscription) {
            this.streamSubscription.stop();
            this.streamSubscription = null
        }
        
        // Deregister listeners
        this.networkUpEventListener()
        this.pageVisibleEventListener()
        this.dashboardRefreshListener()

        // Remove any lingering tooltips
        const elements = document.getElementsByClassName('highcharts-tooltip-container');        
        while(elements.length > 0){
            elements[0].parentNode.removeChild(elements[0]);
        }
    }

    setupDeviceStream() {
        return this.SensorService.subscribeToAllUpdates({eventTypes: this.getStreamEventTypes()}, (event) => {
            
            const deviceId = event.targetName.split('/')[3];
            
            for (let i = 0; i < this.cards.length; i++) {
                const device = this.cards[i].content?.devices?.filter(filteredDevice => filteredDevice.id === deviceId)[0]
                if (!device) {
                    // eslint-disable-next-line no-continue
                    continue
                }

                if (moment(event.timestamp).diff(new Date(), 'seconds') < NETWORK_STATUS_TIME_WINDOW * -1) {
                    return // Backfill events are not processed by the dashboard yet
                }
                
                // Not injecting in new events for aggregated multi-sensor temperature cards
                if (this.cards[i].appearance === DASHBOARD_APPEARANCE_MULTIPLE_TEMPERATURE || 
                    this.checkOnlyTemperatureSensors(this.cards[i]) || 
                    this.cards[i].appearance === DASHBOARD_APPEARANCE_MULTIPLE_HUMIDITY) {
                    return
                }

                // Update the device with the new event
                device.reported[event.eventType] = event.data[event.eventType];
                
                // Don't update the card if we are already fetching content for it
                if (this.cards[i].graphLoading) {
                    return
                }

                // Sets a 'triggering' flag on the card that adds a pulse css animation
                if (!this.isEditingCard) {
                    this.cards[i].triggering = true;
                }

                if (this.cards[i].appearance === DASHBOARD_APPEARANCE_CO2_HEATMAP || this.cards[i].appearance === DASHBOARD_APPEARANCE_MOTION_HEATMAP) {
                    this.cards[i].doneLoading = false // Force update heatmap drawing
                }
                
                this.cards[i].graphLoading = true;
                this.$scope.$applyAsync();
                if (event.eventType === getHistoryEventType(device)) {
                    this.cards[i].content.events[device.name]?.push(event); // eslint-disable-line no-unused-expressions
                }
                
                setTimeout(() => {
                    this.cards[i].graphLoading = false;
                    this.cards[i].doneLoading = true;
                    this.$scope.$applyAsync();
                }, 200);

                setTimeout(() => {
                    this.cards[i].triggering = false;
                    this.$scope.$applyAsync();
                }, 1500);   
            }
        });
    }

    getStreamEventTypes() {
        // Returns all event types, except network status events, since these
        // can be extremely frequent, and don't affect how the dashboard is displayed.
        return [
            'touch',
            'temperature',
            'contact',
            'objectPresent',
            'batteryStatus',
            'labelsChanged',
            'connectionStatus',
            'ethernetStatus',
            'cellularStatus',
            'objectPresentCount',
            'touchCount',
            'humidity',
            'waterPresent',
            'co2',
            'pressure',
            'motion',
            'deskOccupancy',
            'probeWireStatus'
        ];
    }

    getFormattedDuration(duration) {
        switch (duration) {
            case 'CUSTOM':
                return 'Custom'
            case 'TODAY':
                return 'Today'
            case 'YESTERDAY':
                return 'Yesterday'
            case 'LAST_3_DAYS':
                return 'Last 3 days'
            case 'LAST_7_DAYS':
                return 'Last 7 days'
            case 'LAST_30_DAYS':
                return 'Last 30 days'
            case 'LAST_3_MONTHS':
                return 'Last 3 months'
            case 'LAST_6_MONTHS':
                return 'Last 6 months'
            case 'LAST_12_MONTHS':
                return 'Last 12 months'
            case 'LAST_3_YEARS':
                return 'Last 3 years'
            case 'THIS_WEEK':
                return 'This week'
            case 'LAST_WEEK':
                return 'Last week'
            case 'LAST_TWO_WEEKS':
                return 'This & last week'
            default:
                return duration;
        }
    }

    hideRangeOptionsDropdown() {
        this.showRangeOptionsDropdown = false
    }

    getCurrentStartTime() {
        if (this.duration === '') {
            this.duration = 'LAST_7_DAYS'
        }

        switch (this.duration) {
            case 'TODAY':
                return moment().startOf('day')
            case 'YESTERDAY':
                return moment().subtract(1, 'day').startOf('day')
            case 'LAST_3_DAYS':
                return moment().subtract(3, 'day')
            case 'LAST_7_DAYS':
                return moment().subtract(7, 'day')
            case 'LAST_30_DAYS':
                return moment().subtract(30, 'day')
            case 'LAST_3_MONTHS':
                return moment().subtract(3, 'month')
            case 'LAST_6_MONTHS':
                return moment().subtract(6, 'month')
            case 'LAST_12_MONTHS':
                return moment().subtract(12, 'month')
            case 'LAST_3_YEARS':
                return moment().subtract(3, 'year')
            case 'THIS_WEEK':
                return moment().startOf('isoWeek')
            case 'LAST_WEEK':
                return moment().subtract(1, 'week').startOf('isoWeek')
            case 'LAST_TWO_WEEKS':
                return moment().subtract(1, 'week').startOf('isoWeek')
            default:
                this.duration = 'LAST_7_DAYS' // Default for older dashboard with the format e.g. '7d'
                return moment().subtract(7, 'day')
        }
    }

    getCurrentEndTime() {
        switch (this.duration) {
            case 'TODAY':
                return moment().endOf('day')
            case 'YESTERDAY':
                return moment().subtract(1, 'day').endOf('day')
            case 'LAST_3_DAYS':
                return moment().add(15, 'minute') // Slight time padding for better selection when zooming
            case 'LAST_7_DAYS':
                return moment().add(15, 'minute')
            case 'LAST_30_DAYS':
                return moment().add(15, 'minute') 
            case 'THIS_WEEK':
                return moment().endOf('isoWeek')
            case 'LAST_WEEK':
                return moment().subtract(1, 'week').endOf('isoWeek')
            case 'LAST_TWO_WEEKS':
                return moment().endOf('isoWeek')
            default:
                return moment().add(15, 'minute')
        }
    }

    showCreateDashboard() {
        this.creatingDashboard = true
        this.$scope.$applyAsync()
    }

    createNewDashboard() {
        if (this.newDashboardName.length >= 3) { // Prevent 'on-enter' callback to trigger unless a proper name is given
            const config = {
                columnCount: "4",
                dashboardType: "TILED",
                duration: 'LAST_7_DAYS',
                displayName: this.newDashboardName
            }
    
            this.DashboardService.createDashboard(this.ProjectManager.currentProjectId, config).then(response => {
                this.dashboards.push(response.config)
                this.creatingDashboard = false
                this.newDashboardName = ''
                this.setDashboard(response.config)

                this.AnalyticsService.trackEvent("dashboard.dashboard_created")
            })
        }
    }

    setDashboard(dashboard) {
        this.showDashboardsDropdown = false
        if (this.currentDashboard.dashboardId !== dashboard.dashboardId) {
            this.getDashboardConfig(dashboard.dashboardId)
            this.AnalyticsService.trackEvent("dashboard.switched_dashboard")
        }
    }

    deleteDashboard(dashboard) {
        this.DashboardService.deleteDashboard(this.ProjectManager.currentProjectId, dashboard.dashboardId).then(() => {
            const dashboardIndex = this.dashboards.findIndex(d => d.dashboardId === dashboard.dashboardId)
            this.dashboards.splice(dashboardIndex, 1)
            
            // Check if the deleted Dashboard was being shown, switch to a new Dashboard or fallback to 'default'.
            if (this.currentDashboard.dashboardId === dashboard.dashboardId) {
                if (this.dashboards.length > 0) {
                    this.getDashboardConfig(this.dashboards[0].dashboardId)
                } else {
                    this.getDashboardConfig('default')
                }
            }

            this.AnalyticsService.trackEvent("dashboard.dashboard_deleted")

            setTimeout(() => { // Visual delay
                this.toastService.showSimpleTranslated('dashboard_was_deleted')
            }, 300);
            this.$scope.$applyAsync()
        }).catch(error => {
            this.toastService.showSimpleTranslated('dashboard_wasnt_deleted')
            console.error(error); // eslint-disable-line no-console
        })
    }

    updateDashboardName(dashboard) { // Prevent 'on-enter' callback to trigger unless a proper name is given
        if (this.updatedDashboardName.length >= 3) {
            const dashboardConfig = _cloneDeep(dashboard)
            dashboardConfig.displayName = this.updatedDashboardName
    
            const promise = this.DashboardService.updateDashboardConfig(this.ProjectManager.currentProjectId, dashboardConfig).then(() => {
                dashboard.displayName = this.updatedDashboardName // Reference to dashboard in this.dashboards
                
                // Cleanup state
                this.updatedDashboardName = ''
                this.renamingDashboard = false
                this.renamingDashboardId = ''
                if (this.currentDashboard.dashboardId === dashboardConfig.dashboardId) {
                    this.currentDashboard.displayName = dashboardConfig.displayName
                }
                this.$scope.$applyAsync()

                this.AnalyticsService.trackEvent("dashboard.dashboard_renamed")
            }).catch(error => {
                this.toastService.showSimpleTranslated('dashboard_name_wasnt_updated')
                console.error(error); // eslint-disable-line no-console
            })
            this.Loader.promise = promise;
        }
    }

    numberOfSensorsForDashboard(dashboard) { 
        const sensorsSet = new Set()
        dashboard.cards.forEach(card => {
            card.deviceConfig.deviceIds.forEach(id => sensorsSet.add(id))
        })
        return sensorsSet.size
    }

    getDashboardConfig(dashboardId) {
        const promise = this.DashboardService.getDashboardConfig(this.ProjectManager.currentProjectId, dashboardId).then(dashboardConfig => {
            this.currentDashboard = dashboardConfig
            
            // Inject a default name if the displayName is missing
            if (this.currentDashboard.displayName === '') {
                this.currentDashboard.displayName = 'Default Dashboard'
            }

            // Update URL to match new dashboardId (without reloading)
            this.$state.go(States.DASHBOARD_SPECIFIED, {dashboardId}, {notify: false})
            
            // Store last visited Dashboard for this Project
            this.DashboardService.setLastVisitedDashboard(this.ProjectManager.currentProjectId, dashboardId)

            this.duration = dashboardConfig.duration;
            this.zoomStartTime = this.getCurrentStartTime().valueOf();
            this.zoomEndTime = this.getCurrentEndTime().valueOf();
            this.cards = dashboardConfig.cards;

            // Prevent old sensor cache from being displayed when switching between projects
            const ids = [];
            this.cards.forEach(card => {
                ids.push(...card.deviceConfig.deviceIds)
            });
            this.SensorService.sensors({ deviceIds: ids }).then(() =>{
                this.updateCardsContent(this.getCurrentStartTime(), this.getCurrentEndTime())
            });

        }).catch(error => {
            console.error(error); // eslint-disable-line no-console
        })
        this.Loader.promise = promise;
    }

    updateCardsContent(startTime, endTime) {

        if (this.cards.length === 0) {
            return;
        }

        const cardPromises = [];

        this.cards.forEach(card => {
            card.graphLoading = true;

            if (card.appearance === DASHBOARD_APPEARANCE_CO2_HEATMAP || 
                card.appearance === DASHBOARD_APPEARANCE_MOTION_HEATMAP ||
                card.appearance === DASHBOARD_APPEARANCE_MULTIPLE_HUMIDITY || 
                card.appearance === DASHBOARD_APPEARANCE_DESK_OCCUPANCY_AGGREGATED
            ) {
                card.doneLoading = false; // Force redrawing
            }

            this.$scope.$applyAsync();

            const projectId = this.ProjectManager.currentProjectId;
            const promise = this.DashboardService
                .getCardContent(projectId, card, startTime, endTime)
                .then(cardContent => {
                    // Check if some of the devices in the card were not found, meaning the devices
                    // are either deleted or moved to a project the user does not have access to.
                    if (cardContent.notFoundDeviceIDs.length > 0) {
                        card.missingDevices = cardContent.notFoundDeviceIDs.map(id => ({id}));
                        return
                    }

                    // Check if some of the devices are in a different project
                    const devicesInDifferentProjects = cardContent.devices.filter(device => device.name.split('/')[1] !== projectId)
                    if (devicesInDifferentProjects.length > 0) {
                        card.missingDevices = devicesInDifferentProjects;
                        return
                    }

                    // Successfully loaded card
                    card.content = cardContent;
                }).catch(error => {
                    // Unknown error while loading card contents
                    console.error(error); // eslint-disable-line no-console
                    this.toastService.showSimpleTranslated('dashboard_card_failed_to_load')

                    // Will display the cards as could not locate devices
                    card.missingDevices = card.deviceConfig.deviceIds.map(id => ({id}));
                }).finally(() => {
                    // Done loading
                    card.doneLoading = true;
                    card.graphLoading = false;

                    this.$scope.$applyAsync();
                });
            
            cardPromises.push(promise);
        });
        
        this.Loader.promises = cardPromises;
    }

    updateDashboard() {
        if (this.duration === '') {
            this.duration = 'LAST_7_DAYS'
        }

        // Remove events from cards
        const cards = _cloneDeep(this.cards)
        cards.forEach(card => {
            if (card.content) {
                card.content.events = {}
            }
        });
        const dashboardConfig = _cloneDeep(this.currentDashboard) 
        dashboardConfig.cards = cards
        dashboardConfig.duration = this.duration

        let promise = this.DashboardService.updateDashboardConfig(this.ProjectManager.currentProjectId, dashboardConfig).then(() => {
            if (this.cardsToAdd.length > 0) {
                this.cardsToAdd.forEach(card => {

                    promise = this.DashboardService.getCardContent(
                        this.ProjectManager.currentProjectId, 
                        card, 
                        this.getCurrentStartTime(), 
                        this.getCurrentEndTime()
                    ).then(cardContent => {
                        card.content = cardContent;
                        card.doneLoading = true;
                        card.graphLoading = false;
                        
                        this.cardsToAdd = [];
                        this.$scope.$applyAsync();
                        
                    }).catch(error => {
                        console.error(error); // eslint-disable-line no-console
                    });
                });
            }

            // Ensure list of Dashboards are up to date
            this.DashboardService.listDashboards(this.ProjectManager.currentProjectId).then(response => {
                this.dashboards = response.dashboards
            })

        }).catch(error => {
            console.error(error); // eslint-disable-line no-console
        });
        this.Loader.promise = promise;
        return promise;
    }

    setDuration(duration) {
        this.duration = duration;
        this.showRangeOptionsDropdown = false
        this.showResetZoom = false
        this.zoomStartTime = this.getCurrentStartTime().valueOf();
        this.zoomEndTime = this.getCurrentEndTime().valueOf();
        
        // Only actually update the dashboard if the current user has access.
        // This still allows all users to change the duration locally, but you
        // need dashboard modify access to push those changes.
        if (this.canUpdateDashboard) {

            // Remove events from cards
            const cards = _cloneDeep(this.cards)
            cards.forEach(card => {
                if (card.content) {
                    card.content.events = {}
                }
            });

            const dashboardConfig = _cloneDeep(this.currentDashboard) 
            dashboardConfig.cards = cards
            dashboardConfig.duration = this.duration

            const promise = this.DashboardService.updateDashboardConfig(this.ProjectManager.currentProjectId, dashboardConfig).then(() => {
                this.updateCardsContent(this.getCurrentStartTime(), this.getCurrentEndTime());
            }).catch(error => {
                console.error(error); // eslint-disable-line no-console
            });
            this.Loader.promise = promise;
        } else {
            this.updateCardsContent(this.getCurrentStartTime(), this.getCurrentEndTime());
        }

        this.AnalyticsService.trackEvent(`dashboard.duration_changed.${duration}`);

        this.removeTooltips()
    }

    showCreateCardModal() {
        this.DialogService.show({
            class: 'dashboard-modal',
            controller: CreateCardController,
            controllerAs: '$ctrl',
            template: CreateCardTemplate,
            parent: document.body,
            clickOutsideToClose: true,
            escapeToClose: true,
            fullscreen: true,
            locals: {
                addNewCard: this.addNewCard.bind(this)
            }
        });
        
        this.AnalyticsService.trackEvent("dashboard.card_modal_opened")
    }
    
    addNewCard(ids, layout, displayName = '', appearance, includeStats = true, includeEvents = true) {
        const cardLayout = layout.split(',');
        const cols = parseInt(cardLayout[0], 10);
        const rows = parseInt(cardLayout[1], 10);
        const card = {
            cols, rows, y: 0, x: 0, // Adds a new card to the first possible position
            cardType: this.CardType.Device, 
            displayName,
            appearance,
            deviceConfig: {
                deviceIds: ids,
                includeStats,
                includeEvents
            }};
        this.cards.push(card)
        this.cardsToAdd = [card];
        this.updateDashboard()
        
        this.AnalyticsService.trackEvent("dashboard.card_created")
    }

    resetZoom() {
        if (this.duration === 'CUSTOM') {
            this.duration = this.previousDuration
        }

        this.zoomStartTime = this.getCurrentStartTime().valueOf();
        this.zoomEndTime = this.getCurrentEndTime().valueOf();
        
        this.$scope.$broadcast('dashboardResetZoom', {
            min: this.zoomStartTime,
            max: this.zoomEndTime
        });

        this.updateCardsContent(this.getCurrentStartTime(), this.getCurrentEndTime())
        this.showResetZoom = false;

        this.AnalyticsService.trackEvent("dashboard.zoom_reset")
    }

    showRangePicker(ev) {
        this.showingRangePicker = true
        const position = this.$mdPanel.newPanelPosition()
            .relativeTo(ev.target)
            .addPanelPosition(this.$mdPanel.xPosition.CENTER, this.$mdPanel.yPosition.BELOW).withOffsetX('54px').withOffsetY('4px')
            
        const panelAnimation = this.$mdPanel.newPanelAnimation()
            .openFrom(ev.target)
            .withAnimation('md-panel--animation')

        const config = {
            attachTo: document.body,
            controller: RangePickerController,
            controllerAs: '$ctrl',
            template: RangePickerTemplate,
            panelClass: 'md-panel-container arrow arrow-gray',
            animation: panelAnimation,
            bindToController: true,
            position,
            openFrom: ev,
            clickOutsideToClose: true,
            escapeToClose: true,
            focusOnOpen: false,
            zIndex: 100,
            locals: {
                zoomStartTime: this.zoomStartTime,
                zoomEndTime: this.zoomEndTime,
                updateDateRange: this.updateDateRange.bind(this),
                minDate: this.hasLongTermStorageFeatureFlag ? moment().subtract(3, 'year').toDate() : moment().subtract(30, 'day').toDate(),
                maxDate: moment().endOf('isoWeek').toDate(), // Selectable out current week to support showing Mon - Sun
                saveText: 'Update Dashboard'
            },
            onRemoving: () => {
                this.showingRangePicker = false
                this.panelRef.destroy()

            }
        };

        this.$mdPanel.open(config).then(reference => {
            this.panelRef = reference
        })
    }

    updateDateRange(startDate, endDate) {
        this.zoomStartTime = moment(startDate).valueOf()
        this.zoomEndTime = moment(endDate).valueOf()
        this.$rootScope.$emit('dashboardZoom', { min: this.zoomStartTime, max: this.zoomEndTime, showResetZoom: false });
        this.panelRef.close()
    }

    showExtendedStorageInfo() {
        this.DialogService.show({
            controller: ExtendedStorageInfoController,
            controllerAs: '$ctrl',
            template: ExtendedStorageInfoTemplate,
            parent: document.body,
            clickOutsideToClose: true,
        })

        this.AnalyticsService.trackEvent("dashboard.extended_storage_info_opened")
    }

    fullscreenDashboard() {
        const element = document.getElementsByTagName('dt-dashboard-page')[0];
        if (element.requestFullscreen) {
            // Able to perform a proper fullscreen
            element.requestFullscreen();
        } else {
            // Perform a simulated fullscreen by hiding the left menu and header
            // Makes fullscreen functional on iOS devices, even when running as a PWA app
            this.$rootScope.$broadcast(SIMULATE_FULLSCREEN);
        } 
        
        this.isFullscreen = true;
        this.stateTitle = this.ProjectManager.currentProject.displayName;
        window.dispatchEvent(new Event('resize'));
        this.$scope.$applyAsync();
        
        // Extra event to ensure all graphs are resized
        setTimeout(() => { 
            window.dispatchEvent(new Event('resize'));
            this.$scope.$applyAsync();
        }, 600);

        this.AnalyticsService.trackEvent("dashboard.entered_fullscreen")
    }

    exitFullscreen() {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else {
            this.$rootScope.$broadcast(STOP_SIMULATE_FULLSCREEN)
            this.exitFullscreenHandler()
        }

        this.AnalyticsService.trackEvent("dashboard.exited_fullscreen")
    }

    exitFullscreenHandler() {
        if (!document.fullscreenElement) {
            
            this.isFullscreen = false;
            this.stateTitle = DASHBOARD_TITLE;
            window.dispatchEvent(new Event('resize'));
            this.$scope.$applyAsync();

            // Extra event to ensure all graphs are resized
            setTimeout(() => { 
                window.dispatchEvent(new Event('resize'));
                this.$scope.$applyAsync();
            }, 600);
        }
    }

    resizeStart(resizedCard) { // Callback on start resize card
        this.cards.forEach(card => {
            if (card !== resizedCard) {
                card.preventPointerEvents = true;
            }
        });
    }
    
    resizeStop() { // Callback on resized card
        this.cards.forEach(card => {
            card.preventPointerEvents = false;
        });
        setTimeout(() => {
            this.$scope.$broadcast('resizedCard');
            this.updateDashboard()
        }, 300);

        this.AnalyticsService.trackEvent("dashboard.card_resized")
    }

    dragStart(draggedCard) { // Callback on start dragged card
        this.cards.forEach(card => {
            if (card !== draggedCard) {
                card.preventPointerEvents = true;
            }
        });
    }

    dragStop() { // Callback on dragged card
        this.cards.forEach(card => {
            card.preventPointerEvents = false;
        });
        setTimeout(() => {
            this.updateDashboard()
        }, 300);

        this.AnalyticsService.trackEvent("dashboard.card_moved")
    }

    editCard(event) {
        this.cardBeingEdited = event.card;
        this.cards.forEach(card => { // Fade out all cards except card being edited
            if (card !== this.cardBeingEdited) {
                card.faded = true;
            }
        });

        setTimeout(() => { // Delayed visual transition
            this.cardBeingEdited.editing = true;
            this.isEditingCard = true;
            this.stateTitle = 'Renaming card'
            this.$scope.$applyAsync();
        }, 150);
    }

    editDevices(event) {
        this.DialogService.show({
            controller: EditDevicesController,
            template: EditDevicesTemplate,
            controllerAs: '$ctrl',
            parent: document.body,
            clickOutsideToClose: true,
            escapeToClose: true,
            fullscreen: true,
            locals: {
                card: event.card,
                updateCard: this.updateCardAfterDeviceEdit.bind(this)
            }
        })
    }

    updateCardAfterDeviceEdit(devices, card) {
        // Trigger a refresh of the card
        card.doneLoading = false 
        
        // Update included devices
        card.content.devices = card.content.devices.filter(device => devices.includes(device.id)) 
        card.deviceConfig.deviceIds = devices

        // Ensure the content gets reloaded
        this.cardsToAdd = [card] 
        
        this.updateDashboard()
    }

    removeMissingDevices(event) {
        // Trigger a refresh of the card
        event.card.doneLoading = false
        
        // Update included devices
        const missingIds = event.card.missingDevices.map(device => device.id)
        event.card.deviceConfig.deviceIds = event.card.deviceConfig.deviceIds.filter(deviceId => !missingIds.includes(deviceId))
        
        // Ensure the content gets reloaded
        this.cardsToAdd = [event.card]

        this.updateDashboard().then(() => {
            delete event.card.missingDevices 
            event.card.doneLoading = true
        })
    }

    toggleLegend(event) {
        // If `hideLegend` is either not set (undefined) or false
        if (!event.card.deviceConfig.hideLegend) {
            event.card.deviceConfig.hideLegend = true;
        } else {
            event.card.deviceConfig.hideLegend = false;
        }

        // Triggers a refresh of the graph
        event.card.doneLoading = false;
        this.updateDashboard().then(() => {
            event.card.doneLoading = true;
        })
    }

    updateAppearance(event) {
        event.card.doneLoading = false

        // Ensure the correct data is loaded for the new appearance
        this.DashboardService.getCardContent(
            this.ProjectManager.currentProjectId, 
            event.card, 
            this.getCurrentStartTime(), 
            this.getCurrentEndTime()
        ).then(cardContent => {    
            event.card.content = cardContent
            event.card.graphLoading = false
            this.updateDashboard().then(() => {
                event.card.doneLoading = true
            })
        })
    }

    hideCardEdit() {
        this.isEditingCard = false;
        this.stateTitle = this.isFullscreen ? this.ProjectManager.currentProject.displayName : DASHBOARD_TITLE;
        this.cardBeingEdited.editing = false;
        this.cardBeingEdited = null;
        this.$scope.$applyAsync();

        setTimeout(() => { // Delayed visual transition
            this.cards.forEach(card => {
                card.faded = false;
            });
            this.$scope.$applyAsync();
        }, 400);
    }

    saveCardEdit() {
        this.$scope.$broadcast('dashboardSaveCardEdit', this.cardBeingEdited);
        this.hideCardEdit();
        this.updateDashboard().then(() => {
            this.toastService.showSimpleTranslated('dashboard_card_was_renamed');
        });

        this.AnalyticsService.trackEvent("dashboard.card_renamed")
    }

    checkClickOutside(event) {
        // Cancel edit by clicking outside card
        if (this.isEditingCard && event.toElement?.nodeName === 'GRIDSTER') {
            this.hideCardEdit()
        }
        this.showDashboardsDropdown = false
    }

    checkOnlyTemperatureSensors(card) {
        if (card.content?.devices?.length > 1) {
            return card.content.devices.every(device => device.type === "temperature")
        }
        return false
    }

    keyup(event) {
        if (this.isEditingCard) {
            if (event.key === 'Enter') {
                this.saveCardEdit();
            }
            if (event.key === 'Escape') {
                this.hideCardEdit();
            }
        }
    }

    removeTooltips() {
        // Ensure floating DOM tooltips don't get stuck (hacky, but works well for our multiple graphs setup)
        const tooltips = document.getElementsByClassName("heatmap-tooltip")
        Array.from(tooltips).forEach(tooltip => {
            const parentTooltip = tooltip.parentElement.parentElement.parentElement // Beautiful
            if(parentTooltip.tagName === 'DIV') {
                parentTooltip.style.visibility = 'hidden'
                parentTooltip.style.opacity = 0
            }
        });
    }

    removeCard(event) {
        const card = event.card;
        this.cards.splice(this.cards.indexOf(card), 1);
        this.updateDashboard()
    }

}
