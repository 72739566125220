import { DEFAULT_TOKEN, getPageSize, persistPageSize } from 'services/PaginationHelper';
import { CREATE_DATACONNECTOR, DELETE_DATACONNECTOR, UPDATE_DATACONNECTOR } from 'services/Permissions';
import { noop, scrollContainerToActiveChild } from 'services/utils';
import { UNAVAILABLE_METRICS_RESPONSE } from 'services/api/DataConnectorService';
import { States } from '../../app.router';

const PAGE_SIZE_SUFFIX = 'dataconnectors';

/* @ngInject */
export default class DataConnectorsController {
    constructor(
        DataConnectorService,
        Loader,
        DialogService,
        ToastService,
        $q,
        $state,
        $timeout,
        $scope,
        RoleManager
    ) {
        this.DataConnectorService = DataConnectorService;
        this.Loader = Loader;
        this.DialogService = DialogService;
        this.toastService = ToastService;
        this.$q = $q;
        this.$state = $state;
        this.$timeout = $timeout;
        this.$scope = $scope;
        this.RoleManager = RoleManager;
    }

    get canCreateNew() {
        return this.RoleManager.can(CREATE_DATACONNECTOR);
    }

    get canUpdate() {
        return this.RoleManager.can(UPDATE_DATACONNECTOR);
    }

    get canDelete() {
        return this.RoleManager.can(DELETE_DATACONNECTOR);
    }

    get isNewDataConnector() {
        return this.$state.params.dataConnectorId === 'new';
    }

    get isEmptyState() {
        if (this.isNewDataConnector) {
            return false;
        }
        return this.loaded ? this.dataConnectors.length === 0 : false;
    }

    get reachedQuotaLimit() {
        return this.dataConnectors.length >= 10
    }

    get isDetailView() {
        return this.$state.is(States.DATA_CONNECTOR_DETAILS);
    }

    isActive(id) {
        return this.$state.params.dataConnectorId === id;
    }

    $onInit() {
        this.query = '';
        this.labelsLoaded = false;
        this.addFormVisible = false;
        this.pageState = States.DATA_CONNECTORS;

        this.loaded = false;

        this.emptyDataConnector = {
            type: 'HTTP_PUSH',
            status: 'ACTIVE',
            events: [],
            labels: [],
            httpConfig: {
                url: '',
                secret: '',
                headers: {}
            }
        };

        this.currentPageSize = getPageSize(PAGE_SIZE_SUFFIX);
        this.currentPageToken = DEFAULT_TOKEN;
        this.nextPageToken = null;

        this.requestParams = {
            pageSize: this.currentPageSize,
            pageToken: this.currentPageToken
        };

        this.dataConnectors = [];

        this.noop = noop;

        this.loadDataConnectors()
            .then(() => this.$timeout(scrollContainerToActiveChild))
    }

    setPageToken({ pageToken }) {
        this.requestParams.pageToken = pageToken;
        this.loadDataConnectors();
    }

    setPageSize({ pageSize }) {
        persistPageSize(pageSize, PAGE_SIZE_SUFFIX);
        this.requestParams.pageToken = DEFAULT_TOKEN;
        this.requestParams.pageSize = pageSize;
        this.loadDataConnectors();
    }

    updateDataConnector({ dataConnector, patch }) {
        const updateMask = "display_name,status,events,labels,http_config";
        const promise = this.DataConnectorService.updateDataConnector(dataConnector.name, patch, updateMask)
            .then((updatedDataConnector) => {
                const dataConnectorRef = this.dataConnectors.find(item => item.name === updatedDataConnector.name);
                Object.assign(dataConnectorRef, updatedDataConnector);

                this.toastService.showSimpleTranslated('dataconnector_was_updated');
            }).catch((serverResponse) => {
                this.toastService.showSimpleTranslated('dataconnector_wasnt_updated', {
                    serverResponse
                });
            });
        this.Loader.promise = promise;
        return promise;
    }

    createDataConnector({ dataConnector }) {
        const promise = this.DataConnectorService.createDataConnector(dataConnector)
            .then((createdDataConnector) => {
                this.dataConnectors.unshift(createdDataConnector);

                this.$state.go(States.DATA_CONNECTOR_DETAILS, {
                    dataConnectorId: createdDataConnector.id,
                    dataConnector: createdDataConnector
                });

                this.toastService.showSimpleTranslated('dataconnector_was_created');
            }).catch((serverResponse) => {
                this.toastService.showSimpleTranslated('dataconnector_wasnt_created', {
                    serverResponse
                });
            });
        this.Loader.promise = promise;
        return promise;
    }

    showDeleteConfirmation({ dataConnector }) {
        return this.DialogService.confirm({
            title: 'Delete Data Connector?',
            textContent: `Do you really want to delete data connector "${dataConnector.displayName}"?`,
            ariaLabel: 'delete-data-connector',
            ok: 'Delete',
            cancel: 'Cancel'
        }).then(() => {
            this.deleteDataConnector(dataConnector);
        });
    }

    deleteDataConnector(dataConnector) {
        this.Loader.promise = this.DataConnectorService.deleteDataConnector(dataConnector.name)
            .then(() => {
                this.dataConnectors = this.dataConnectors.filter(connector => connector.name !== dataConnector.name);

                this.toastService.showSimpleTranslated('dataconnector_was_removed');
            }).catch((serverResponse) => {
                this.toastService.showSimpleTranslated('dataconnector_wasnt_removed', {
                    serverResponse
                });
            });
        this.$state.go(States.DATA_CONNECTORS);
    }

    loadDataConnectors() {
        const promise = this.DataConnectorService
            .dataConnectors(this.requestParams)
            .then(({ data, nextPageToken }) => {
                this.currentPageSize = this.requestParams.pageSize;
                this.currentPageToken = this.requestParams.pageToken;
                this.nextPageToken = nextPageToken;
                this.dataConnectors = data;
                this.loaded = true;
            }).catch((serverResponse) => {
                this.toastService.showSimpleTranslated('dataconnectors_wasnt_fetched', {
                    serverResponse
                });
            });
        this.Loader.promise = promise;
        return promise;
    }

    showAddForm() {
        this.addFormVisible = true;
    }

    updateMetrics(event, dataConnector) {
        const targetNode = event.target;
        targetNode.classList.add('spinning');
        this.DataConnectorService
            .dataConnectorMetrics(dataConnector.name)
            .then((response) => {
                Object.assign(dataConnector, response.metrics);
                this.toastService.showSimpleTranslated('dataconnector_metrics_was_refreshed');
            })
            .catch((serverResponse) => {
                Object.assign(dataConnector, UNAVAILABLE_METRICS_RESPONSE.metrics);
                this.toastService.showSimpleTranslated('dataconnector_metrics_wasnt_refreshed', {
                    serverResponse
                });
            })
            .finally(() => {
                targetNode.classList.remove('spinning');
            });
    }

    mergeMetrics({ dataConnector, metrics }) {
        const targetDataConnector = this.dataConnectors.find(item => item.id === dataConnector.id);
        if (targetDataConnector) {
            Object.assign(targetDataConnector, metrics);
        }
    }
}
